:root {
  --amber_100: #ffb600;
  --amber_80: #ffc533;
  --amber_60: #ffd366;
  --amber_40: #ffe299;
  --amber_20: #ffe9b3;
  --purple_100: #6b4eff;
  --purple_80: #8971ff;
  --purple_60: #a695ff;
  --purple_40: #c4b8ff;
  --purple_20: #d3caff;
  --blue_100: #307fe2;
  --blue_80: #5999e8;
  --blue_60: #83b2ee;
  --blue_40: #acccf3;
  --blue_20: #c1d9f6;
  --rose_100: #e31c79;
  --rose_80: #e94994;
  --rose_60: #ee77af;
  --rose_40: #f4a4c9;
  --rose_20: #f7bbd7;
  --black: #000000;
  --white: #ffffff;
  --grey: #808485;
  --sky_light: #e3e4e5;
  --sky_lightest: #f7f9fa;
  --ink_light: #6c7072;
  --sky_dark: #979c9e;
  --hurrey_48: 48px;
  --hurrey_36: 36px;
  --hurrey_28: 28px;
  --hurrey_24: 24px;
  --hurrey_20: 20px;
  --hurrey_16: 16px;
  --hurrey_12: 12px;
}
