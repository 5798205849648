@font-face {
  font-family: "Primary-Font";
  src: url(./assets/fonts/Gellix-Regular.ttf);
}

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "Primary-Font" !important;
}

body {
  line-height: normal;
}

.medium_box_shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
  transition: box-shadow 0.3s ease; /* Smooth transition */
}

.medium_box_shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 20px; /* Highlighted shadow */
}


.boxShadow {
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
